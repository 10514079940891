<template>
  <section
    class="other-attractions-widget"
    :class="{ 'other-attractions-widget--flipped': widget.is_flipped }"
  >
    <div class="main-wrapper">
      <component
        :is="widget.video_video_id ? 'button' : 'div'"
        class="media-container"
        @click="
          widget.video_video_id &&
            open(widget.video_video_id, widget.video_is_vimeo)
        "
      >
        <BaseImage
          class="media"
          :image="widget.image"
          :img-sizes="[
            { w: 768, h: 576 },
            { w: 1366, h: 1026 }
          ]"
          sizes="100vw"
          :width="widget.image.width"
          :height="widget.image.height"
        />
      </component>

      <div class="tiles">
        <BaseLink :link="widget.blue_link" class="tile tile--primary">
          <TileCover1Icon class="tile__cover" />
          <span class="tile__text">
            <span
              class="tile__underline"
              v-html="nbsp(widget.blue_link.label)"
            />
          </span>
          <ArrowRightIcon class="tile__arrow" />
        </BaseLink>

        <BaseLink :link="widget.orange_link" class="tile tile--secondary">
          <TileCover2Icon class="tile__cover" />
          <span class="tile__text">
            <span
              class="tile__underline"
              v-html="nbsp(widget.orange_link.label)"
            />
          </span>
          <ArrowRightIcon class="tile__arrow" />
        </BaseLink>
      </div>
    </div>

    <TextBox
      class="text-box"
      :title="widget.title"
      :description="widget.content"
    />
  </section>
</template>

<script lang="ts" setup>
import ArrowRightIcon from '@/assets/icons/arrow-right.svg?component'
import TileCover1Icon from '@/assets/icons/tile-cover-1.svg?component'
import TileCover2Icon from '@/assets/icons/tile-cover-2.svg?component'
import type { PublicOtherAttractionsWidget } from '@/service/__generated-api'
import type { Widget } from '@/types/utils'

defineProps<Widget<PublicOtherAttractionsWidget>>()

const { open } = useVideoPopup()
</script>

<style lang="scss" scoped>
.other-attractions-widget {
  position: relative;
}

.main-wrapper {
  position: relative;
}

.media-container {
  display: block;
}

.media {
  aspect-ratio: 360 / 540;

  @include media-up(md) {
    aspect-ratio: 768 / 576;
  }

  @include media-up(lg) {
    aspect-ratio: 1366 / 1026;
  }
}

.tiles {
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  @include media-up(md) {
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 50%;
  }

  .other-attractions-widget--flipped & {
    @include media-up(md) {
      right: auto;
      left: 0;
    }
  }
}

.tile {
  @include font-size(
    (
      xs: 18px,
      lg: 32px,
      xl: 46px
    ),
    1.12
  );
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  aspect-ratio: 1/1;
  padding: rem(26px);
  padding-bottom: rem(21px);
  font-weight: 400;
  color: $beige;
  text-align: left;
  letter-spacing: -0.025em;
  word-break: break-word;
  pointer-events: auto;

  @include media-up(lg) {
    padding: rem(42px);
  }

  @include media-up(xl) {
    padding: rem(60px);
  }

  .contrast-mode & {
    color: $contrast-black;
  }

  &--primary {
    margin-left: auto;
    background: $blue;

    .other-attractions-widget--flipped & {
      margin-left: 0;
    }
  }

  &--secondary {
    background: $orange;

    .other-attractions-widget--flipped & {
      margin-left: auto;
    }
  }

  &__cover {
    @include fill-space;
    pointer-events: none;
    transform: scale(0.85);
  }

  &__underline {
    white-space: pre-wrap;
    background-image: linear-gradient(
      transparent calc(100% - rem(2px)),
      currentColor rem(2px)
    );
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size $transition-slow;

    .tile:hover & {
      background-size: 100% 100%;
    }
  }

  &__arrow {
    width: rem(18px);
    height: rem(9px);
    transition: transform $transition;

    @include media-up(lg) {
      width: rem(34px);
      height: rem(16px);
    }

    @include media-up(xl) {
      width: rem(48px);
      height: rem(23px);
    }

    .tile:hover & {
      transform: translateX(50%);
    }
  }
}

.text-box {
  @include media-up(md) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    min-height: 0;
    aspect-ratio: 2/1;
  }

  .other-attractions-widget--flipped & {
    @include media-up(md) {
      right: 0;
      left: auto;
    }
  }

  .homepage-section:nth-child(2n) & {
    @include media-up(md) {
      margin-left: auto;
    }
  }
}
</style>
